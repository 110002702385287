const ACADEMIC_LEVEL = {
    WITHOUT_EDUCATION_ID: 1,
    INCOMPLETE_PRIMARY_ID: 2,
    COMPLETE_PRIMARY_ID: 3,
    INCOMPLETE_SECONDARY_ID: 4,
    COMPLETE_SECONDARY_ID: 5,
    INCOMPLETE_TECHNICIAN_ID: 6,
    COMPLETE_TECHNICIAN_ID: 7,
    INCOMPLETE_UNIVERSITY_ID: 8,
    COMPLETE_UNIVERSITY_ID: 9,
};

export default ACADEMIC_LEVEL