const AuthRoutes = [
    '/iniciar-sesion',
    '/tipo-de-cuenta',
    '/postulante/registro',
    '/empresa/registro',
    '/municipalidad/registro',
    '/postulante/inicio',
    '/postulante/perfil',
    '/recuperar-contraseña',
    '/new-password',
    '/terminos-y-condiciones-operativa',
    '/politicas-de-privacidad',
];

export default AuthRoutes;