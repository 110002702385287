export const dashboardType = {
    SET_JOBS_INFO: 'SET_JOBS_INFO',
    SET_JOBS_INFO_ERROR: 'SET_JOBS_INFO_ERROR',
    SET_PUBLICATIONS_INFO: 'SET_PUBLICATIONS_INFO',
    SET_PUBLICATIONS_INFO_ERROR: 'SET_PUBLICATIONS_INFO_ERROR',
    SET_UPDATE_PUBLICATION_ERROR: 'SET_UPDATE_PUBLICATION_ERROR',
    SET_SAVE_PUBLICATION_ERROR: 'SET_SAVE_PUBLICATION_ERROR',
    SET_PUBLICATION_SELECTED: 'SET_PUBLICATION_SELECTED',
    SET_ARCHIVE_PUBLICATION_ERROR: 'SET_ARCHIVE_PUBLICATION_ERROR',
    SET_DELETE_PUBLICATION_ERROR: 'SET_DELETE_PUBLICATION_ERROR',
    SET_POSTULANTS_BY_PUBLICATION_ID: 'SET_POSTULANTS_BY_PUBLICATION_ID',
    SET_POSTULANTS_BY_PUBLICATION_ID_ERROR: 'SET_POSTULANTS_BY_PUBLICATION_ID_ERROR',
    SET_PROFILE_OF_APPLICANT: 'SET_PROFILE_OF_APPLICANT',
    SET_PROFILE_OF_APPLICANT_ERROR: 'SET_PROFILE_OF_APPLICANT_ERROR',
    SET_HISTORY_OF_PUBLICATIONS: 'SET_HISTORY_OF_PUBLICATIONS',
    SET_REPORT_BY_POSTULANT_ID: 'SET_REPORT_BY_POSTULANT_ID',
    SET_ERROR_FETCH: 'SET_ERROR_FETCH',
    SET_REQUEST_STATE: 'SET_REQUEST_STATE',
};
