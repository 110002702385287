export const homeType = {
    SET_ERROR_FETCH: 'SET_ERROR_FETCH',

    SET_FETCH_LOADING: 'HOME/FETCH_LOADING',
    SET_FETCH_ERROR: 'HOME/FETCH_ERROR',
    SET_FETCH_SUCCESS: 'HOME/FETCH_SUCCESS',
    SET_FETCH_IDLE: 'HOME/FETCH_IDLE',

    SEND_EMAIL: 'HOME/SEND_EMAIL',
    SET_IS_POSTULANT: 'SET_IS_POSTULANT',
};
