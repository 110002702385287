export const authType = {
    SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
    SIGN_UP: 'SIGN_UP',
    SET_USER: 'SET_USER',
    SET_USER_ERROR: 'SET_USER_ERROR',
    CLEAR_SIGN_UP: 'CLEAR_USER',
    FETCH_LOGIN: 'FETCH_LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    SHOW_LOADING: 'SHOW_LOADING',
    SET_CORPORATION_DATA: 'SET_CORPORATION_DATA',
    SET_REPRENSENTATIVE_DATA: 'SET_REPRENSENTATIVE_DATA',
    SET_STEP: 'SET_STEP',
    LOGIN_AS: 'AUTH/LOGIN_AS',
};
