const PROVIDERS = {
    MUNICIPALITY_ID: 1,
    ONG_ID: 2,
    RED_SOCIAL_ID: 3,
    RADIO_ID: 4,
    NEWSPAPER_ID: 5,
    INSTITUTE_ID: 6,
    OTHER: 7,
};

export default PROVIDERS
